import CustomDivider from "../CustomDivider";
import ProductFeatures from "../Product/ProductFeatures";
import SectionHeading from "../SectionHeading";
import "./styles.scss";

const productFeatures = [
  {
    imgUrl: "/images/home_1/cabg.jpg",
    title: "Cardiology: Heart Failure",
  },
  {
    imgUrl: "/images/home_1/knee-hip-replacement.jpg",
    title: "Orthopedics: Knee/Hip Replacement",
  },
  {
    imgUrl: "/images/home_1/spinal-fusion.jpg",
    title: "Orthopedics: Spinal Fusion",
  },
];

const HomeSection2 = () => {
  return (
    <section className="section">
      <div className="container">
        <CustomDivider
          title={
            <SectionHeading
              title="<span className='gradient-color'>Focus areas</span>"
              center
              fontsize="3rem"
              style={{ marginBottom: "0px" }}
            />
          }
        />
      </div>
      <ProductFeatures
        logo
        logoSize={40}
        productFeatures={productFeatures}
        title="We understand that each area of post-acute follow-up care deserves special attention."
        titleOnTop
        titleStyle={{ fontWeight: "500", margin: "unset" }}
        headingCentered
      />
    </section>
  );
};

export default HomeSection2;
