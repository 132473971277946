import React, { useState, useEffect } from "react";
import "./styles.scss";

const EmailSentStrip = () => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    visible && (
      <div className="email-sent-message">
        Your form has been submitted successfully! We will get back to you soon.
      </div>
    )
  );
};

export default EmailSentStrip;
