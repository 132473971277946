import SectionHeading from "../SectionHeading";
import "./styles.scss";

const HomeSection1 = () => {
  return (
    <section className="home-section-1 section">
      <div className="container">
        <div className="home-section-1-wrapper">
          <div className="home-section-1-content">
            <SectionHeading
              title="Facilitating a smooth patient recovery"
              bullet
              fontsize="28"
            />
            <SectionHeading
              title="Customizable follow-up care"
              bullet
              fontsize="28"
            />
            <SectionHeading
              title="Simple integration into hospital workflows"
              bullet
              fontsize="28"
            />
          </div>
          <div className="video-container">
            <img
              className="video-background"
              src="/images/home_1/how-it-works.jpg"
              alt="Nurse holding a patient
              "
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeSection1;
