import "./styles.scss";

import Slider from "react-infinite-logo-slider";

const AutoScrollingCarousel = ({ data }) => {
  return (
    <div className="slider-container">
      <Slider
        width="250px"
        duration={25}
        pauseOnHover={true}
        blurBorders
        blurBoderColor={"#fff"}
      >
        {data?.map((item, index) => (
          <Slider.Slide className={`brand_logo width_${item.width}`}>
            <img key={index} src={item.imgUrl} alt={item.imgAlt} />
          </Slider.Slide>
        ))}
      </Slider>
    </div>
  );
};

export default AutoScrollingCarousel;
