import SectionHeading from "../../SectionHeading";
import DepartmentCarousel from "../../Slider/DepartmentCarousel";

import "./styles.scss";

const ProductFeatures = ({
  slides = 3,
  logo = false,
  logoSize,
  productFeatures = [],
  title = "",
  titleLogo = false,
  titleOnTop = false,
  titleStyle,
  noZoom = false,
  headingCentered = false,
}) => {
  return (
    <div className="features-section container">
      <section className="section">
        {title && (
          <SectionHeading
            title={title}
            logo={titleLogo}
            fontsize="28"
            center={headingCentered}
          />
        )}
        <DepartmentCarousel
          data={productFeatures}
          logo={logo}
          logoSize={logoSize}
          noZoom={noZoom}
          title={title}
          titleOnTop={titleOnTop}
          titleStyle={titleStyle}
          slides={slides}
        />
      </section>
    </div>
  );
};

export default ProductFeatures;
