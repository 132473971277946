import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "./styles.scss";
import Button from "../Button";
import { EMAIL_ACCESS_KEY } from "../../constants";
import Spinner from "../Spinner";
import EmailSentStrip from "../EmailSentStrip";

const EarlyAccessForm = ({ handleClose }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [emailSendStatus, setEmailSendStatus] = useState("");

  const onSubmit = async (data) => {
    console.log("Form Data Submitted:", data);
    // You can process the form data here (e.g., send to API)
    const formData = new FormData();

    formData.append("access_key", EMAIL_ACCESS_KEY);

    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });

    setEmailSendStatus("sending");
    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      setEmailSendStatus("sent");
      reset(); // Reset the form to its default values
    } else {
      setEmailSendStatus("error");
    }
  };

  return (
    <div className="early-access-form">
      <h2 className="gradient-color">Join our early access program!</h2>
      <p>
        Sign up here for a chance to be among the first to try the
        conversational AI part of our solution.
      </p>
      {emailSendStatus === "sending" && <Spinner />}
      {emailSendStatus === "sent" && <EmailSentStrip />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="custom_input">
          <label className="cs_input_label cs_heading_color">
            Full Name <span className="cs_accent_color">*</span>
          </label>
          <input
            className="cs_form_field"
            {...register("fullname", { required: "Full name is required" })}
            placeholder="Enter your full name"
          />
          {errors.fullname && (
            <span className="cs_error_color">{errors.fullname.message}</span>
          )}
        </div>
        <div className="custom_input">
          <label className="cs_input_label cs_heading_color">
            Company Email <span className="cs_accent_color">*</span>
          </label>
          <input
            className="cs_form_field"
            {...register("companyemail", {
              required: "Company email is required",
            })}
            placeholder="Enter your company email"
          />
          {errors.companyemail && (
            <span className="cs_error_color">
              {errors.companyemail.message}
            </span>
          )}
        </div>
        <div className="custom_input">
          <label className="cs_input_label cs_heading_color">
            Role <span className="cs_accent_color">*</span>
          </label>
          <input
            className="cs_form_field"
            {...register("role", { required: "Role is required" })}
            placeholder="Enter your role"
          />
          {errors.role && (
            <span className="cs_error_color">{errors.role.message}</span>
          )}
        </div>
        <div className="custom_input">
          <label className="cs_input_label cs_heading_color">
            Organization <span className="cs_accent_color">*</span>
          </label>
          <input
            className="cs_form_field"
            {...register("organization", {
              required: "Organization is required",
            })}
            placeholder="Enter your organization's name"
          />
          {errors.organization && (
            <span className="cs_error_color">
              {errors.organization.message}
            </span>
          )}
        </div>
        <div className="custom_input">
          <label className="cs_input_label cs_heading_color">
            Additional Comments
          </label>
          <textarea
            className="cs_form_field"
            {...register("additionalComment")}
            placeholder="Enter additional comments here"
          />
          {errors.additionalComment && (
            <span className="cs_error_color">
              {errors.additionalComment.message}
            </span>
          )}
        </div>
        <div>
          <label>
            <input
              className="terms-checkbox"
              type="checkbox"
              {...register("acceptedTerms", { required: true })}
            />
            <span>
              I understand that joining this waitlist does not create an
              official partnership or endorsement by my affiliated institution.
            </span>
            {errors.acceptedTerms && (
              <div className="cs_error_color">This field is required</div>
            )}
          </label>
        </div>
        <div className="form-footer">
          <Button
            onClick={handleClose}
            btnText="Close"
            variant="btn_secondary"
            removeArrows
          />
          <button type="submit" className="cs_btn cs_style_1">
            <span>Join early access</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default EarlyAccessForm;
