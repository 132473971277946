import React from "react";
import { pageTitle } from "../../helpers/PageTitle";
import Hero from "../Hero";
import HomeSection1 from "../HomeSection1";
import HomeSection2 from "../HomeSection2";
import HomeSection3 from "../HomeSection3";
import HomeSection4 from "../HomeSection4";
import BrandsSection from "../Section/BrandsSection";

const brandData = [
  {
    imgUrl: "images/partners/grayed/ny-headline.png",
    imgAlt: "Logo of NY Headline",
    width: "220",
  },
  {
    imgUrl: "images/partners/grayed/nbc.png",
    imgAlt: "Logo of NBC",
    width: "230",
  },
  {
    imgUrl: "images/partners/grayed/star-tribune.png",
    imgAlt: "Logo of Star Tribune",
    width: "250",
  },
  {
    imgUrl: "images/partners/grayed/associated-press-1.png",
    imgAlt: "Logo of Associated Press",
    width: "320",
  },
  {
    imgUrl: "images/partners/grayed/usa-news.jpg",
    imgAlt: "Logo of USA News",
    width: "220",
  },
  {
    imgUrl: "images/partners/grayed/digital-journal.png",
    imgAlt: "Logo of Digital Journal",
    width: "300",
  },
];

export default function Home() {
  pageTitle("Home");
  return (
    <>
      <Hero
        title="<div className='gradient-color'> Reducing Readmissions,</div>
               <div className='gradient-color title-2'>Empowering Healthcare Teams.</div>"
        showRequestModal
        aspectRatio={1.77}
      />

      <HomeSection1 />
      <HomeSection2 />
      <HomeSection3 />
      <HomeSection4 />

      <BrandsSection sectionTitle="In Media" data={brandData} />
    </>
  );
}
