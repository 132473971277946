import "./styles.scss";

const AboutIcon = ({ color = "#c90c29", size = "25" }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 512.000000 505.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,505.000000) scale(0.100000,-0.100000)"
        fill={color}
        stroke="none"
      >
        <path
          d="M885 4962 c-120 -42 -205 -117 -258 -230 -27 -57 -32 -81 -35 -164
l-4 -98 -80 0 c-95 0 -118 -10 -118 -50 0 -40 23 -50 119 -50 l81 0 0 -330 0
-330 -88 0 c-93 0 -112 -8 -112 -46 0 -34 20 -43 110 -46 l85 -3 3 -327 2
-328 -84 0 c-92 0 -116 -11 -116 -54 0 -38 19 -46 112 -46 l88 0 0 -330 0
-330 -88 0 c-93 0 -112 -8 -112 -46 0 -38 36 -54 122 -54 l78 0 -2 -327 -3
-328 -85 -3 c-90 -3 -110 -12 -110 -46 0 -38 19 -46 112 -46 l88 0 0 -330 0
-330 -81 0 c-96 0 -119 -10 -119 -50 0 -40 23 -50 118 -50 l80 0 4 -99 c3 -89
7 -105 41 -174 45 -90 112 -156 207 -201 l65 -31 1720 -3 c1291 -2 1734 0
1775 9 125 26 236 116 293 236 l32 68 0 2135 0 2135 -31 65 c-45 95 -111 162
-202 207 l-76 38 -1740 2 c-1632 2 -1744 1 -1791 -15z m490 -2432 l0 -2355
-210 0 c-261 0 -290 8 -381 99 -71 71 -93 122 -101 239 l-6 77 75 0 c72 0 76
-1 82 -25 9 -37 65 -96 111 -116 51 -24 119 -24 171 0 149 68 166 274 32 368
-55 37 -147 44 -205 15 -46 -24 -100 -81 -109 -117 -6 -24 -10 -25 -80 -25
l-74 0 0 330 0 330 69 0 70 0 28 -52 c76 -141 265 -152 357 -20 42 59 48 147
14 212 -82 158 -300 155 -378 -5 l-22 -46 -67 3 -66 3 -3 328 -2 327 73 0 73
0 12 -34 c15 -39 70 -92 118 -112 76 -32 173 -7 231 59 90 102 62 261 -57 325
-37 19 -56 23 -111 20 -87 -5 -137 -36 -170 -107 l-24 -50 -72 -1 -73 0 0 330
0 330 73 0 72 -1 23 -48 c33 -71 86 -105 171 -109 55 -3 74 1 111 20 119 64
147 223 57 325 -85 96 -230 96 -314 -1 -18 -20 -36 -48 -39 -61 -6 -24 -10
-25 -80 -25 l-74 0 2 328 3 327 68 3 c65 3 67 2 78 -25 28 -74 121 -143 193
-143 126 0 225 107 214 234 -17 210 -300 262 -396 74 l-25 -48 -68 0 -69 0 0
330 0 330 74 0 c70 0 74 -1 80 -25 9 -37 65 -96 111 -116 138 -63 295 40 295
192 0 151 -167 257 -299 190 -45 -23 -98 -81 -107 -116 -6 -24 -10 -25 -82
-25 l-75 0 6 87 c12 171 112 291 268 322 24 5 130 8 234 8 l190 -2 0 -2355z
m3050 2336 c72 -28 166 -124 191 -196 18 -53 19 -113 19 -2140 0 -2395 11
-2146 -99 -2256 -109 -109 48 -99 -1616 -99 l-1445 0 -3 2345 c-1 1290 0 2351
3 2358 4 10 289 12 1452 10 1401 -3 1450 -4 1498 -22z m-3325 -355 c50 -36 57
-124 14 -172 -26 -29 -79 -40 -121 -26 -101 34 -102 178 -2 214 40 14 74 9
109 -16z m2 -758 c30 -26 49 -77 42 -112 -10 -46 -66 -91 -114 -91 -72 0 -128
74 -109 144 10 33 35 65 64 77 30 13 94 3 117 -18z m6 -762 c58 -59 44 -146
-30 -186 -34 -18 -40 -19 -79 -5 -54 19 -82 56 -81 109 1 70 44 110 119 111
33 0 48 -6 71 -29z m-20 -741 c100 -61 59 -210 -58 -210 -37 0 -81 23 -99 52
-16 25 -17 89 -2 117 16 29 69 61 101 61 14 0 40 -9 58 -20z m2 -757 c52 -35
68 -84 46 -136 -22 -52 -52 -72 -106 -72 -38 0 -54 6 -74 24 -52 49 -51 124 4
174 36 32 92 36 130 10z m10 -762 c50 -37 57 -124 14 -172 -26 -29 -79 -40
-121 -26 -48 16 -76 55 -76 106 0 98 104 151 183 92z"
        />
        <path
          d="M1999 4463 c-15 -2 -51 -19 -80 -36 -66 -39 -115 -114 -124 -191 -3
-28 -5 -224 -3 -436 3 -366 4 -387 24 -430 26 -57 76 -106 134 -134 45 -21 45
-21 1105 -21 1054 0 1060 0 1105 21 58 26 108 76 134 134 20 43 21 60 21 475
l0 430 -30 54 c-30 54 -61 83 -125 117 -33 18 -80 19 -1085 20 -577 1 -1062
-1 -1076 -3z m2147 -119 c15 -11 37 -33 48 -48 20 -27 21 -39 21 -455 l0 -428
-27 -35 c-55 -73 21 -68 -1133 -68 -1154 0 -1078 -5 -1133 68 l-27 35 -3 401
c-2 221 0 415 3 433 7 40 49 91 90 109 24 11 223 13 1082 11 1045 -2 1052 -2
1079 -23z"
        />
        <path
          d="M2482 4098 c-19 -19 -15 -66 7 -78 13 -6 209 -10 566 -10 357 0 553
4 566 10 22 12 26 59 7 78 -17 17 -1129 17 -1146 0z"
        />
        <path
          d="M2488 3659 c-25 -14 -24 -65 1 -79 13 -6 209 -10 566 -10 357 0 553
4 566 10 12 7 19 21 19 40 0 19 -7 33 -19 40 -27 14 -1109 13 -1133 -1z"
        />
      </g>
    </svg>
  );
};

export default AboutIcon;
