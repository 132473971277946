import React, { useState } from "react";
import Button from "../../Button";
import "./styles.scss";
import EarlyAccessForm from "..";

const EarlyAccessFormModal = () => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <Button
        variant="open-modal-btn"
        onClick={handleShow}
        btnText="Join early access"
      />

      {show && (
        <div
          className="early-access-form-modal modal show"
          style={{ display: "block" }}
          tabIndex="-1"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal_header">
                <button type="button" className="close" onClick={handleClose}>
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <EarlyAccessForm handleClose={handleClose} />
              </div>
            </div>
          </div>
        </div>
      )}

      {show && <div className="modal-backdrop show"></div>}
    </>
  );
};

export default EarlyAccessFormModal;
