import "./styles.scss";

const DemoIcon = ({ color = "#c90c29", size = "25" }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={color}
        stroke="none"
      >
        <path
          d="M672 4669 c-232 -30 -420 -184 -511 -418 l-26 -66 -3 -1190 c-3
-1326 -6 -1264 65 -1408 51 -102 171 -221 273 -270 143 -70 97 -67 1087 -67
l893 0 0 -295 0 -295 -483 0 c-531 0 -510 2 -543 -62 -21 -41 -11 -87 27 -124
l30 -29 1079 0 1080 0 30 30 c40 41 48 89 20 133 -11 18 -32 37 -46 42 -15 6
-216 10 -500 10 l-474 0 0 295 0 295 893 0 c990 0 944 -3 1087 67 102 49 222
168 273 270 71 144 68 83 65 1403 l-3 1185 -22 65 c-81 235 -280 399 -523 429
-102 13 -3670 13 -3768 0z m3841 -237 c112 -45 196 -133 241 -250 21 -57 21
-63 24 -1019 l3 -963 -2221 0 -2220 0 0 913 c0 511 5 944 10 985 17 129 85
235 191 299 110 66 -25 62 2039 60 l1875 -2 58 -23z m263 -2549 c-12 -158 -64
-262 -169 -339 -117 -86 72 -79 -2017 -82 -2083 -3 -1944 -8 -2059 69 -129 86
-189 215 -191 407 l0 42 2221 0 2222 0 -7 -97z"
        />
      </g>
    </svg>
  );
};

export default DemoIcon;
