import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { EMAIL_ACCESS_KEY } from "../../../constants";
import EmailSentStrip from "../../EmailSentStrip";
import Spinner from "../../Spinner";
import "./styles.scss";

const defaultValues = {
  firstname: "",
  lastname: "",
  institute: "",
  phone: "",
  email: "",
  linkedin: "",
};

const DemoRequestForm = () => {
  const form = useRef();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues });
  const [emailSendStatus, setEmailSendStatus] = useState("");

  const onSubmit = async (data) => {
    const formData = new FormData();

    formData.append("access_key", EMAIL_ACCESS_KEY);

    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });

    setEmailSendStatus("sending");
    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      setEmailSendStatus("sent");
      reset(); // Reset the form to its default values
    } else {
      setEmailSendStatus("error");
    }
  };

  return (
    <div className="demo_request_form_cont">
      {emailSendStatus === "sending" && <Spinner />}
      {emailSendStatus === "sent" && <EmailSentStrip />}
      <form ref={form} onSubmit={handleSubmit(onSubmit)}>
        <div className="custom_input">
          <label className="cs_input_label cs_heading_color">
            Full Name <span className="cs_accent_color">*</span>
          </label>
          <input
            className="cs_form_field"
            {...register("fullname", { required: "Full name is required" })}
            placeholder="Enter your full name"
          />
          {errors.fullname && (
            <span className="cs_error_color">{errors.fullname.message}</span>
          )}
        </div>
        <div className="custom_input">
          <label className="cs_input_label cs_heading_color">
            Company Email <span className="cs_accent_color">*</span>
          </label>
          <input
            className="cs_form_field"
            {...register("companyemail", {
              required: "Company email is required",
            })}
            placeholder="Enter your company email"
          />
          {errors.companyemail && (
            <span className="cs_error_color">
              {errors.companyemail.message}
            </span>
          )}
        </div>
        <div className="custom_input">
          <label className="cs_input_label cs_heading_color">
            Role <span className="cs_accent_color">*</span>
          </label>
          <input
            className="cs_form_field"
            {...register("role", { required: "Role is required" })}
            placeholder="Enter your role"
          />
          {errors.role && (
            <span className="cs_error_color">{errors.role.message}</span>
          )}
        </div>
        <div className="custom_input">
          <label className="cs_input_label cs_heading_color">
            Organization <span className="cs_accent_color">*</span>
          </label>
          <input
            className="cs_form_field"
            {...register("organization", {
              required: "Organization is required",
            })}
            placeholder="Enter your organization's name"
          />
          {errors.organization && (
            <span className="cs_error_color">
              {errors.organization.message}
            </span>
          )}
        </div>
        <div className="custom_input">
          <label className="cs_input_label cs_heading_color">
            Additional Comments
          </label>
          <textarea
            className="cs_form_field"
            {...register("additionalComment")}
            placeholder="Enter additional comments here"
          />
          {errors.additionalComment && (
            <span className="cs_error_color">
              {errors.additionalComment.message}
            </span>
          )}
        </div>
        <div>
          <label>
            <input
              className="terms-checkbox"
              type="checkbox"
              {...register("acceptedTerms", { required: true })}
            />
            <span>
              I understand that joining this waitlist does not create an
              official partnership or endorsement by my affiliated institution.
            </span>
            {errors.acceptedTerms && (
              <div className="cs_error_color">This field is required</div>
            )}
          </label>
        </div>
        <button type="submit" className="cs_btn cs_style_1">
          <span>Join waitlist</span>
        </button>
      </form>
    </div>
  );
};

export default DemoRequestForm;
